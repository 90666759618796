import { useEffect, useState } from "react"
import { wait } from "../services/wait"
import { Burger, Pan, Plate } from "./Game.type"

const defaultPans: Pan[] = [{
    status: "empty",
    time: 0
}, {
    status: "empty",
    time: 0
}, {
    status: "empty",
    time: 0
}]

const defaultPlates: Plate<Burger>[] = [{
    food: {
        meat: false,
        bread: false,
        cheese: false,
        salad: false
    },
    time: 0
}, {
    food: {
        meat: false,
        bread: false,
        cheese: false,
        salad: false
    },
    time: 0
}, {
    food: {
        meat: false,
        bread: false,
        cheese: false,
        salad: false
    },
    time: 0
}]

const PAN_COOKING_TIME = 3000
const PAN_BURNING_TIME = 3000

export const Game: React.FC = () => {


    const [pans, setPans] = useState<Pan[]>(defaultPans)
    const [plates, setPlates] = useState<Plate<Burger>[]>(defaultPlates)
    const [servedPlates, setServedPlates] = useState<Plate<Burger>[]>([])
    const clickPan = (index: number) => {
        async function main() {
            const currentPan = pans[index]

            if (currentPan.status === "cooking") {
                return
            }

            if (currentPan.status === "burning") {
                addMeatToBread(index)
                return
            }

            if (currentPan.status === "burned") {
                setPans((pans) => {
                    const newPans = [...pans]
                    newPans[index] = {
                        status: "empty",
                        time: 0
                    }
                    return newPans
                })
                return
            }

            const newPans = [...pans]
            newPans[index] = {
                status: "cooking",
                time: Date.now()
            }
            setPans(newPans)

            await wait(PAN_COOKING_TIME)
            setPans((pans) => {
                const newPans = [...pans]
                newPans[index] = {
                    status: "burning",
                    time: Date.now()
                }
                setPans(newPans)

                return pans
            })

            await wait(PAN_BURNING_TIME)
            setPans((pans) => {
                if (pans[index].status !== "burning") {
                    return pans
                }
                const newPans = [...pans]
                newPans[index] = {
                    status: "burned",
                    time: Date.now()
                }

                return newPans
            })
        }

        main()
    }

    const addMeatToBread = (index: number) => {
        const plateIndex = plates.findIndex(plate => plate.food.meat === false && plate.food.bread)

        if (plateIndex === -1) {
            return
        }

        setPans((pans) => {
            const newPans = [...pans]
            newPans[index] = {
                status: "empty",
                time: 0
            }
            return newPans
        })

        setPlates((plates) => {
            const newPlates = [...plates]
            newPlates[plateIndex].food.meat = true
            newPlates[plateIndex].time = Date.now()
            return newPlates
        })
    }

    const servePlate = (index: number) => {
        if (plates[index].food.meat === false && plates[index].food.bread === false && plates[index].food.cheese === false) {
            return
        }
        setServedPlates((servedPlates) => {
            const newServedPlates = [...servedPlates]
            newServedPlates.push(plates[index])
            return newServedPlates
        })
        setPlates((plates) => {
            const newPlates = [...plates]
            newPlates[index] = {
                food: {
                    meat: false,
                    bread: false,
                    cheese: false,
                    salad: false
                },
                time: 0
            }
            return newPlates
        })
    }

    const addBread = () => {
        const plateWithoutBreadIndex = plates.findIndex(plate => plate.food.bread === false)
        if (plateWithoutBreadIndex === -1) {
            return
        }
        setPlates((plates) => {
            const newPlates = [...plates]
            newPlates[plateWithoutBreadIndex].food.bread = true
            newPlates[plateWithoutBreadIndex].time = Date.now()
            return newPlates
        })
    }

    const addCheese = () => {
        const plateWithoutCheeseIndex = plates.findIndex(plate => plate.food.cheese === false && plate.food.bread)
        if (plateWithoutCheeseIndex === -1) {
            return
        }
        setPlates((plates) => {
            const newPlates = [...plates]
            newPlates[plateWithoutCheeseIndex].food.cheese = true
            newPlates[plateWithoutCheeseIndex].time = Date.now()
            return newPlates
        })

    }

    const addSalad = () => {
        const plateWithoutSaladIndex = plates.findIndex(plate => plate.food.salad === false && plate.food.bread)
        if (plateWithoutSaladIndex === -1) {
            return
        }
        setPlates((plates) => {
            const newPlates = [...plates]
            newPlates[plateWithoutSaladIndex].food.salad = true
            newPlates[plateWithoutSaladIndex].time = Date.now()
            return newPlates
        })
    }

    return <>
        <h1>Burger Kitchen 🍔</h1>
        <div className="bg-red-300">
            <h2>Pfannen</h2>
        </div>
        <div className="bg-red-300 flex gap-4 text-5xl">
            {pans.map((pan, index) => {
                return <button onClick={() => clickPan(index)} className="w-48 h-48 bg-slate-400 flex flex-col items-center" key={index}>
                    <p className="mt-auto">
                        {pan.status === "cooking" ? "🍳" : ""}
                        {pan.status === "burning" ? "🥩" : ""}
                        {pan.status === "burned" ? "🔥" : ""}
                    </p>
                    <div className="mt-auto mb-4 relative w-32 h-4 bg-gray-200 rounded-full overflow-hidden">
                        {pan.status === "cooking" && <div className="absolute top-0 left-0 h-full bg-blue-500 rounded-full animate-progress"></div>}
                        {(pan.status === "burning" || pan.status === "burned") && <div className="absolute top-0 left-0 h-full bg-red-500 rounded-full animate-progress"></div>}
                    </div>
                </button>

            })}
        </div>

        <div className="bg-green-300">
            <h2>Teller</h2>
        </div>
        <div className="bg-blue-300 flex gap-4 text-2xl">
            {plates.map((plate, index) => {
                return <button className="w-48 h-48 bg-slate-100" key={index} onClick={() => servePlate(index)}>
                    <p>{plate.food.meat ? "🥩" : ""}</p>
                    <p>{plate.food.cheese ? "🧀" : ""}</p>
                    <p>{plate.food.bread ? "🍞" : ""}</p>
                    <p>{plate.food.salad ? "🥬" : ""}</p>
                </button>
            })}
        </div>

        <div className="bg-gray-400">
            <h2>Zutaten</h2>
        </div>
        <div className="bg-gray-400 flex gap-4 text-5xl">
            <button className="w-48 h-48 bg-white" onClick={addBread}>🍞</button>
            <button className="w-48 h-48 bg-white" onClick={addCheese}>🧀</button>
            <button className="w-48 h-48 bg-white" onClick={addSalad}>🥬</button>
        </div>

        <div className="bg-yellow-300">
            <h2>Servierte Teller</h2>
        </div>
        <div className="bg-yellow-300 flex gap-4 text-2xl">
            {servedPlates.map((plate, index) => {
                return <button className="w-48 h-48 bg-slate-100" key={index}>
                    <p>{plate.food.meat ? "🥩" : ""}</p>
                    <p>{plate.food.cheese ? "🧀" : ""}</p>
                    <p>{plate.food.bread ? "🍞" : ""}</p>
                    <p>{plate.food.salad ? "🥬" : ""}</p>
                </button>
            })}
        </div>
    </>
}